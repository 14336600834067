<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@/components";
import ArticleFrame from "./components/ArticleFrame.vue";

export default {
  name: "FiveStepsToImprovePacingInYourStory",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="FiveStepsToImprovePacingInYourStory">
      <SuawParagraph
        text="Unlike tone and voice, two of the more slippery elements of storytelling, pacing is an element that lends itself to more fine-grained control. Whether you’re working on a first draft or editing a later one, here’s how you can maneuver pacing in your story."
      />
      <SuawHeading level="3" content="1. Decide on Your Pacing" />
      <SuawParagraph
        text="First, decide on what kind of pace you’d like to set for your project. If you’re not sure, think about your favorite books. Are you a fan of fast-paced thrillers or slow-burning contemplative pieces? Do you like to feel breathless or calm as you turn the page? Referring to your favorite works of literature is a great way to set yourself up to write something that inspires you."
      />
      <SuawParagraph
        text="Another option is to read some of your previous writing with a particular eye on pacing. How does it feel to read a paragraph or a page in one of your own stories? Are there details you wish you had lingered on? Conversely, are there any sleepy areas in your prose which could use tightening up?"
      />
      <SuawParagraph text="Feel free to take notes on the kinds of sentences and paragraphs that appeal to you, as well as ones you want to improve." />
      <SuawHeading level="3" content="2. Experiment With the Length of Sentences, Paragraphs, and Chapters" />
      <SuawParagraph
        text="Once you have an idea of the kind of pacing that most inspires you, start small: with each sentence. In writing, form defines function to a staggering degree. The length of your sentences translates directly into the pace of the action itself. Shorter sentences, faster to read, can create a sense of fast-paced action. On the other hand, longer sentences, with many subordinate clauses, that go meandering into the inner thoughts of your characters or express a gesture with particular verbosity, if done well, can create a feeling of slow-burning indulgence for your reader. (See?)"
      />
      <SuawParagraph text="The same general principle applies to paragraphs and chapters." />
      <SuawParagraph
        text="Keep in mind that varying the length of sentences and paragraphs, though not necessarily chapters, is a crucial way to create a rhythm in your writing. You don’t want the same length for all of your sentences and paragraphs because this can read as monotonous. This exercise is more about practicing the relative proportions of short versus long sentences, with the goal of striking a balance."
      />
      <SuawHeading level="3" content="3. Expand on Sensory Detail at Critical Moments" />
      <SuawParagraph
        text="During the most momentous scenes in your story, like when your protagonist comes face-to-face with a threat, or a character dies, or a treacherous abandonment occurs, use heightened sensory detail to place the reader right in the scene. What does the villain’s face look like? Is there a particular smell in the air or a sound that the protagonist will forever remember? Linger on the sensory detail for longer than you would during a less intense moment. Describe that trailing bead of sweat or quivering lower lip, that metallic taste of blood."
      />
      <SuawParagraph
        text="It may seem counterintuitive, but slowing down at the height of the action is crucial for imprinting its significance in the mind of the reader. Note that slowing down is relative to the pacing of the surrounding part of the scene—if you’re aiming to write at a fast clip, slowing down could mean one additional sentence. For more contemplative pieces, you might devote an entire paragraph to describing the light in the room in the moment of the act."
      />
      <SuawHeading level="3" content="4. Utilize Introspection to Establish Motivations" />
      <SuawParagraph
        text="What does your main character think about the villain’s hair when she first sees her? How does your protagonist feel when he’s made to wait longer than he expected? Which triggers launch your heroine into a character-developing flashback?"
      />
      <SuawParagraph
        text="Introspection can help you slow down so that your reader understands the significance of what is happening in your story world. This is critical to establishing character motivation, which has to be believable enough for your audience to keep reading. Introspection can happen in the form of a flashback or an internal monologue, and it’s a great way to uncover details about your protagonist’s background which are new even to you."
      />
      <SuawHeading level="3" content="5. Get Feedback" />
      <SuawParagraph
        text="Once you’ve reworked the pacing in your piece, make sure to go over your work with fresh eyes after letting it sit for a while. Read it out loud to get a feeling for your pacing. If you find yourself tripping over a turn of phrase or pausing at awkward moments, consider those areas as candidates for revision."
      />
      <SuawParagraph
        text="After a round or two of edits, make sure to get outside feedback from alpha or beta readers and let them know you’re interested in pacing. What feels essential to you may read like fluff to them, or vice versa, so make use of their outside perspective to guide any subsequent rounds of edits you may want to make."
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
